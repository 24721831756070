<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="submissionError" />
    <div class="w-full text-center" v-else-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <asom-card v-else>
      <asom-grid :sm="1" :md="2">
        <asom-alert class="col-span-2" v-if="submissionError" variant="error" :error-message="submissionError" />
        <asom-form-field
          label="Email Subject"
          :state="inputStates('formData.subject')"
        >
          <asom-input-text
            v-model="formData.subject"
            :state="inputStates('formData.subject')"
          />
        </asom-form-field>
        <div></div>
        <asom-form-field
          class="col-span-2"
          label="Email Body"
          :state="inputStates('formData.body')"
        >
          <!-- <asom-input-text-editor v-if="!isEditMode"
            v-model="templateBodyToDisplay"
            readOnly="true"
            contentType="html"
            :state="inputStates('templateBodyToDisplay')"
          />
          <asom-input-text-editor v-else
            v-model="formData.body"
            contentType="text"
            :state="inputStates('formData.body')"
          /> -->
          <div class="space-y-1">
            <asom-alert variant="info" title="Read-only Body Content">
              <p class="text-sm pt-1">The email body is read-only due to variables in the content. Please contact relevant SMRT personnel to edit body content.</p>
            </asom-alert>
            <asom-input-text-editor
              readOnly="true"
              v-model="formData.body"
              contentType="text"
              :state="inputStates('formData.body')"
            />
          </div>
        </asom-form-field>
        <!-- <div class="col-span-2 flex justify-end space-x-4">
          <asom-button v-if="!isEditMode"
            text="Edit"
            @click="onEdit"
            :disabled="isSubmitting"
            variant="secondary"
          />
          <div class="col-span-2 flex justify-end space-x-4" v-else>
            <asom-button 
            text="Cancel"
            @click="cancelEdit"
            :disabled="isSubmitting"
            variant="secondary"
            />
            <asom-button
            text="Done"
            @click="updateDisplayTemplate"
            :disabled="isSubmitting"
            />
          </div>
        </div> -->
        <asom-form-field
          label="To: / CC Emails"
          :state="inputStates('formData.cc')"
        >
          <asom-input-multi-email
            v-model="formData.cc"
            :state="inputStates('formData.cc')"
          />
        </asom-form-field>
        <div></div>
        <asom-form-field
          label="BCC Emails"
          :state="inputStates('formData.bcc')"
        >
          <asom-input-multi-email
            v-model="formData.bcc"
            :state="inputStates('formData.bcc')"
          />
        </asom-form-field>
        <div class="col-span-2 flex justify-end space-x-4">
          <asom-button
            text="Cancel"
            variant="secondary"
            @click="$router.go(-1)"
          />
          <asom-button
            text="Submit"
            @click="updateEmailTemplate"
            :loading="isSubmitting"
            :disabled="isSubmitting || isEditMode"
          />
        </div>
      </asom-grid>
    </asom-card>
  </div>
</template>

<script>
import get from 'lodash.get';
import { getAsync, postAsync } from '../../services/serviceHandlers';
import { EDIT_EMAIL_TEMPLATE, EMAIL_TEMPLATE_DETAILS } from '../../constants/apis';
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from 'vuex';
import AsomInputMultiEmail from '@/components/input/multi_email/AsomInputMultiEmail.vue';

export default {
  mixins: [inputStates],
  components: {
    AsomInputMultiEmail,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      isLoading: false,
      error: null,
      isSubmitting: false,
      submissionError: null,
      formData: {
        category: null,
        subject: null,
        body: null,
        cc: null,
        bcc: null,
        params: null,
      },
      isEditMode: false,
      templateBodyToDisplay: null
    }
  },
  validations() {
    return {
      lineId: { required }, 
      formData: {
        category: { required },
        subject: { required },
        body: { required },
      }
    }
  },
  mounted() {
    this.loadPageData();
  },
  computed: {
    ...mapGetters({
      lineId: 'auth/userLineId'
    })
  },
  methods: {
    async loadPageData() {
      const id = get(this.$route, 'params.asomsNotificationTemplateId');
      this.isLoading = true;
      this.error = null;
      const result = await getAsync({
        url: EMAIL_TEMPLATE_DETAILS,
        params: {
          NotificationTemplateId: id,
        }
      });
      if (result.success) {
        this.setFormData(get(result.payload, 'data'));
      } else {
        this.error = result.payload;
      }
      this.isLoading = false;
    },
    setFormData(template) {
      this.formData.category = get(template, 'notificationCategoryEnum');
      this.formData.subject = get(template, 'templateSubject');
      this.formData.body = get(template, 'templateBody');
      this.templateBodyToDisplay = get(template, 'templateBody');
      this.formData.cc = get(template, 'cc');
      this.formData.bcc = get(template, 'bcc');
      this.formData.params = get(template, 'params');
    },
    async updateEmailTemplate() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.isSubmitting = true;
        const result = await postAsync({
          url: EDIT_EMAIL_TEMPLATE,
          body: {
            lineId: this.lineId,
            category: this.formData.category,
            subject: this.formData.subject,
            templateBody: this.formData.body,
            CC: this.formData.cc,
            BCC: this.formData.bcc,
          }
        });
        this.isSubmitting = false;
        if (result.success) this.$router.push({ name: "Admin Configuration" });
        else {
          this.submissionError = result.payload;
          this.$scrollTop();
        }
      } else {
        this.submissionError = "Please complete all required fields";
      }
    },
    cancelEdit(){
      this.isEditMode = false;
    },
    updateDisplayTemplate(){
      this.templateBodyToDisplay = this.formData.body;
      this.isEditMode = false;
    },
    onEdit(){
      this.isEditMode = true;
    }
  }
}
</script>

<style>

</style>